import React, { useEffect, useRef } from "react"
import { useWindowSize } from "../hooks/windowsize"
import Button from "./button"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const Cards = props => {
  const size = useWindowSize()
  const ref = useRef(null)
  const ref_container = useRef(null)
  const tl = useRef(gsap.timeline({ paused: true }))
  const cardrefs = useRef([])

  const pin = useRef(gsap.timeline({ paused: true, ease: "none" }))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    ref_container.current.style.height = size.height + "px"

    const refCurrentClientHeight = ref.current.clientHeight
    const refCurrentOffsetTop = ref.current.offsetTop

    pin.current.to(
      {},
      {
        scrollTrigger: {
          id: "Cards",
          trigger: ref.current,
          start: "top top",
          end: "+=" + size.height * cardrefs.current.length,
          pin: ref.current,
        },
      }
    )

    gsap.defaults({
      duration: 1.2,
      ease: "customEaseOut",
    })

    cardrefs.current.forEach((v, i) => {
      let rot = gsap.utils.random([-5, 5, 3, -3])

      gsap.set(v, { rotation: rot })

      const u = rot > 0 ? 1 : -1

      tl.current.to(v, {
        x: 40 * u,
        y: -120,
        rotation: "+=" + 0.1 * u,
        duration: 1.2,
        ease: "none",
        scrollTrigger: {
          id: "Card_" + i,
          trigger: ref.current,
          start: refCurrentOffsetTop,
          end: "+=" + refCurrentClientHeight * (i + 1),
          scrub: 0.5,
          toggleActions: "play pause resume reset",
          onEnterBack: () => {
            gsap.to(v, {
              opacity: 1,
              duration: 1,
              overwrite: "auto",
            })
          },
          onLeave: () => {
            gsap.to(v, {
              x: "+=" + 100 * u,
              y: "-=1200",
              rotation: "+=" + 0.35 * u,
              duration: 1.6,
              ease: "power2.out",
            })
            gsap.to(v, {
              opacity: 0,
              duration: 0.8,
              delay: 0.8,
              ease: "power2.out",
            })
          },
        },
      })
    })

    const sticky = pin.current
    const timeline = tl.current

    return () => {
      sticky.kill()
      sticky.getChildren().map(v => {
        return v.scrollTrigger.kill()
      })
      timeline.kill()
      timeline.getChildren().map(v => {
        return v.scrollTrigger.kill()
      })
    }
  }, [size])

  const cardMouseLeave = (e, ref) => {
    gsap.to(ref, {
      scale: 1,
      duration: 0.3,
    })
  }

  const cardMouseEnter = (e, ref) => {
    gsap.to(ref, {
      scale: 1.05,
      duration: 0.3,
    })
  }

  return (
    <div ref={ref}>
      <div className="cards__wrapper" ref={ref_container}>
        {props.cards.map((o, i) => (
          <div
            key={i}
            className="card color-black"
            ref={ref => (cardrefs.current[i] = ref)}
            role="presentation"
            style={{ zIndex: props.cards.length - i }}
            onMouseEnter={e => cardMouseEnter(e, cardrefs.current[i])}
            onMouseLeave={e => cardMouseLeave(e, cardrefs.current[i])}
          >
            <h2 className="card__title text--sm sp-mb-3">
              {o.node.frontmatter.title}
            </h2>
            <div
              className="card__content text--md"
              dangerouslySetInnerHTML={{ __html: o.node.html }}
            ></div>
            {o.node.frontmatter.buttons && (
              <div className="card__link">
                {o.node.frontmatter.buttons.map((v, j) => (
                  <Button
                    key={j}
                    to={v.link}
                    className="button button--light button--icon"
                  >
                    {v.button}
                  </Button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Cards
