import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CanvasGrid from "../components/canvas-grid"
import Carousel from "../components/carousel"
import AppTicker from "../components/appticker"
import Cards from "../components/cards"
import Icon from "@mdi/react"
import { mdiEmailOutline } from "@mdi/js"

const PhotoshootsPage = ({ data }) => {
  const { t } = useTranslation("photoshoots")

  return (
    <Layout>
      <SEO
        title={t("metadata.title")}
        description={t("metadata.desc")}
        image="shoots.jpg"
      />

      <CanvasGrid class="canvas-grid--light" theme="light" />

      <div className="container-fluid sp-pb-9 color--black page-intro">
        <div className="row">
          <div className="content text--sm offset-lg-2 col-lg-8 col-xl-6">
            <h1 className="title text--sm">{t("hero.title")}</h1>
            <p>{t("hero.content")}</p>
          </div>
        </div>
      </div>

      <Carousel
        className="sp-pb-9"
        images={data.carouselImages.nodes}
        slidesPerView={3.5}
        spaceBetween={75}
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          576: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3.5,
            spaceBetween: 75,
          },
        }}
      />

      <div className="container-fluid color--black sp-mt-9 sp-mb-9">
        <div className="row text-center">
          <div className="col-md-10 m-auto">
            <div className="title text--sm sp-mb-4">{t("equipment.title")}</div>
            <div className="content text--lg">{t("equipment.content")}</div>
          </div>
        </div>
      </div>

      <Cards cards={data.cards.edges} theme="light" />

      <div className="container-fluid sp-mt-9 sp-mb-9">
        <div className="row text-center">
          <div className="col-md-10 m-auto color--black">
            <div className="title text--sm sp-mb-4">
              {t("tailor_made.title")}
            </div>
            <div
              className="content text--lg sp-mb-7"
              dangerouslySetInnerHTML={{ __html: t("tailor_made.content") }}
            ></div>
            <a
              href="mailto:hello@thehighloft.com"
              className="button button--light button--icon"
            >
              hello@thehighloft.com
              <span className="icon">
                <Icon path={mdiEmailOutline} />
              </span>
            </a>
          </div>
        </div>
      </div>

      <Img fluid={data.pageImages.nodes[0].childImageSharp.fluid} />

      <AppTicker theme="light" />
    </Layout>
  )
}

export const query = graphql`
  query PhotoshootsQuery($language: String) {
    pageImages: allFile(
      filter: { relativePath: { regex: "/photoshoots_[0-9]+.jpg/" } }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    cards: allMarkdownRemark(
      filter: {
        frontmatter: { lang: { eq: $language } }
        fileAbsolutePath: { regex: "/photoshoots/cards/" }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            buttons {
              button
              link
            }
          }
          html
        }
      }
    }
    carouselImages: allFile(
      filter: { relativePath: { regex: "/photoshoots/carousel/" } }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default PhotoshootsPage
